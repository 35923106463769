<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <div class="card card-body">
            <a href="https://play.google.com/store/apps/details?id=com.bustahdir.admin" target="_blank" class='btn btn-primary'>
                <i class="fa fa-external-link"></i>
                تطبيق المشرف للاندرويد
            </a> 
            <br>
            <a href="https://play.google.com/store/apps/details?id=com.bustahdir.parent" target="_blank" class='btn btn-primary'>
                <i class="fa fa-external-link"></i>
                تطبيق ولي الامر للاندرويد
            </a> 
            <br>

            <a href="https://apps.apple.com/sa/app/%D8%B5%D9%82%D8%B1-%D9%84%D9%84%D8%AA%D9%88%D8%B5%D9%8A%D9%84-%D8%A7%D9%84%D9%85%D8%AF%D8%B1%D8%B3%D9%8A/id6447880192?l=ar" target="_blank" class='btn btn-primary'>
                <i class="fa fa-external-link"></i>
                تطبيق ولي الامر للايفون
            </a> 

        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>